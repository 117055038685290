// ShowcaseComponent.jsx

import React from 'react';
import './CourseSec.css';
const CourseSec = () => {
  return (
    <section className="course-showcase">
      <img
        src="https://images.shiksha.com/mediadata/ugcDocuments/images/wordpressImages/2020_05_software-development-i1.jpg"
        alt="Picture"
      />
      <div className="overlay">
        <h2>HOME | <span>ContactBody</span></h2>
        <p> Welcome to <strong>Kaizen</strong>, where education meets innovation! We empower individuals through knowledge with comprehensive and cutting-edge training.</p>
      </div>
    </section>
  );
};

export default CourseSec;
