import React from 'react';
import '@splidejs/splide/css';
import './ScrollSection.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';

const SplideComponent = () => {
  const options = {
    type: 'fade',
    direction: 'ttb',
    height: '416px',
  };

  return (
    <div className="container-slide">
      <Splide aria-label="Splide Basic HTML Example" options={options}>
        <SplideSlide>
          <div className="wp-block-columns">
            <div className="wp-block-column">
              <figure className="wp-block-image">
                <img src="/Images/About/Personality_Development.webp" alt="PersonalityDevelopment" />
                <img src="/Images/About/Personality_Development.webp" alt="PersonalityDevelopment" />
              </figure>
            </div>
            <div className="wp-block-column">
              <h2>Personality Development</h2>
              <p>Engage in activities designed to enhance your personal growth and improve your communication, leadership, and social skills. These activities are essential for building a strong, well-rounded personality.</p>
              <button>Learn More</button>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="wp-block-columns">
            <div className="wp-block-column">
              <figure className="wp-block-image">
                <img src="/Images/About/Team_Work.webp" alt="TeamWork" />
                <img src="/Images/About/Team_Work.webp" alt="TeamWork" />
              </figure>
            </div>
            <div className="wp-block-column">
              <h2>Team Work</h2>
              <p>Effective teamwork is crucial for achieving common goals. Learn how to collaborate with others, leverage each team member’s strengths, and improve collective performance.</p>
              <button>Learn More</button>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="wp-block-columns">
            <div className="wp-block-column">
              <figure className="wp-block-image">
                <img src="/Images/About/intership.webp" alt="Internship" />
                <img src="/Images/About/intership.webp" alt="Internship" />
              </figure>
            </div>
            <div className="wp-block-column">
              <h2>Internship</h2>
              <p>Gain hands-on experience in your field of interest through internships. These opportunities provide valuable insights into the professional world and help you build a strong foundation for your career.</p>
              <button>Learn More</button>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="wp-block-columns">
            <div className="wp-block-column">
              <figure className="wp-block-image">
                <img src="/Images/About/project_management_coursefees.avif" alt="ProjectManagement" />
                <img src="/Images/About/project_management_coursefees.avif" alt="ProjectManagement" />
              </figure>
            </div>
            <div className="wp-block-column">
              <h2>Project Management</h2>
              <p>Master the art of project management by learning how to plan, execute, and close projects efficiently. Develop skills in risk management, budgeting, and team leadership to ensure project success.</p>
              <button>Learn More</button>
            </div>
          </div>
        </SplideSlide>
      </Splide>
    </div>
  );
};

export default SplideComponent;
