// AboutUs.jsx

import React from 'react';
import './AboutSec.css'; // Import the CSS file

const AboutUs = () => {
  return (
    <div className="about-section">
      <img
        id="about-image"
        src="/Images/About/About-1.jpg" 
        alt="AboutUs"
      />
      <div className="about-content">
        <h2>
        Grow your business with Kaizen</h2>
       <h4>We are team of talented designers making classic websites
       </h4>
      </div>
    </div>
  );
};

export default AboutUs;
