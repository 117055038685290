import React from 'react';

import SoftwareTrainingSlider from './AboutSlider';
import AboutUs from './AboutSec';
import AboutBody from './AboutBody';
import Vision from './Vision';

import SplideComponent from '../Home/ScrollSection';
import TeamSection from './Team';

function MainAbout() {
  return (
    <div className="App">
      <AboutBody/>
      <AboutUs/>
     
      <Vision/>
      <SplideComponent/>
      <SoftwareTrainingSlider/>
      <TeamSection/>
    </div>
  );
}

export default MainAbout;
