import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Client.css";

const SimpleSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1200, // 3 slides for large screens
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 992, // 2 slides for medium screens
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576, // 1 slide for small screens
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const images = [
        {
            src: "/Images/Home/Infosys_logo.svg.png",
            alt: "Infosys Logo"
        },
        {
            src: "/Images/Home/Deloitte_Logo.png",
            alt: "Deloitte"
        },
        {
            src: "/Images/Home/Wipro-logo.png",
            alt: "Wipro Logo"
        },
        {
            src: "/Images/Home/Tech_Mahindra-Logo.Wine.png",
            alt: "Tech Mahindra Logo"
        },
        {
            src: "/Images/Home/Capgemini-Logo.png",
            alt: "CapGemini"
        },
        {
            src: "/Images/Home/HCL_Technologies_logo.svg.png",
            alt: "HCL"
        }
    ];

    return (
        <div className='Clients'>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} className='mnc'>
                        <img src={image.src} alt={image.alt} />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default SimpleSlider;
