import React from 'react';

import ContactForm from './ContactForm';
import ContactBody from './ContactBody';

function MainContact() {
  return (
    <div className="App">
      <ContactBody/>
    <ContactForm/>
    </div>
  );
}

export default MainContact;
