
import React, { useState } from 'react';
import { NavLink,} from 'react-router-dom';

import { CiCircleList } from "react-icons/ci";

import "./navbar.css";

// NavBar component
function Header() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const handleDropdown = () => setDropdown(!dropdown);


  
  


  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <img src="/Images/Logo.png" alt="Logo" />
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About
              </NavLink>
            </li>
            <li className="nav-item" onMouseEnter={handleDropdown} onMouseLeave={handleDropdown}>
              <NavLink
                exact
                to="/course"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Course
              </NavLink>
             
            </li>
            
            <li className="nav-item">
              <NavLink
                exact
                to="/blog"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Blog
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact 
              </NavLink>
            </li>
            
            {/* Add Join and Login buttons */}
            <button className="custom-button" >
              
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdVNfUev2XBo0lOsK768x128SNohVOntjVi9wsqutBTm4g9Aw/viewform" className="custom-button">
                Join
              </a>
            </button>

            
         

           
                <div>
                <button className="join-button">
                  Login
                </button>
               
                </div>
              
       
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {/* Your icon or menu toggle button */}
            <CiCircleList className="Nav-icon"/>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
