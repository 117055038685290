// ShowcaseComponent.jsx

import React from 'react';
import '../Blog/BlogSec.css';
const ContactBody = () => {
  return (
    <section className="showcase">
      <img
        src="https://blog.ipleaders.in/wp-content/uploads/2021/05/online-course-blog-header.jpg"
        alt="Picture"
      />
      <div className="overlay">
        <h2>HOME | <span>Contact</span></h2>
      </div>
    </section>
  );
};

export default ContactBody;
