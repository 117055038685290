import React from 'react';

import Gallery from './Gallery';
import CourseWebsite from './Courses';
import CourseSec from './CourseSec';

function MainCourse() {
  return (
    <div className="App">
      <CourseSec/>
      <CourseWebsite/>
     
    </div>
  );
}

export default MainCourse;
