import React from 'react';
import BlogSec from './BlogSec';
import Blog from './Blog';


const MainBlog = () => {
    return (
        <section>
           <BlogSec/>
           <Blog/>
        </section>
    );
};

export default MainBlog;
