import React from 'react';
import './About.css';

const cardsData = [
  {
    id: 1,
    image: 'https://preview.colorlib.com/theme/courses/assets/img/icon/icon1.svg',
    title: 'Projects',
    description: 'Web Development Projects Idea at Siftforanswers.',
  },
  {
    id: 2,
    image: 'https://preview.colorlib.com/theme/courses/assets/img/icon/icon2.svg',
    title: 'Testing',
    description: 'Scripting checks on the form are working as expected. ',
  },
  {
    id: 3,
    image: 'https://preview.colorlib.com/theme/courses/assets/img/icon/icon3.svg',
    title: 'Developing',
    description: 'Developing mobile application,Clone Application',
  },
];

const CardComponent = ({ image, title, description }) => {
  return (
    <div className="card">
      <img src={image} alt={title} className="card-imag" style={{width:'70px'}} />
      <div className="card-content">
        <h1 className="card-title">{title}</h1>
        <p className="card-description">{description}</p>
      </div>
    </div>
  );
};

const Stats = () => {
  return (
    <div className="card-container">
      {cardsData.map((card) => (
        <CardComponent
          key={card.id}
          image={card.image}
          title={card.title}
          description={card.description}
        />
      ))}
    </div>
  );
};

export default Stats;
