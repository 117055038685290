import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainHome from './Home/MainHome';
import MainAbout from './About/MainAbout';
import MainCourse from './OurCourses/MainCourses';
import Blog from './Blog/Blog';
import MainContact from './Contact/MainContact';
import Header from './Home/Navbar';
import Footer from './Home/Footer';
import MainBlog from './Blog/MainBlog';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<MainHome />} />
        <Route path="/about" element={<MainAbout />} />
        <Route path="/course" element={<MainCourse />} />
        <Route path="/blog" element={<MainBlog />} />
        <Route path="/contact" element={<MainContact />} />
       
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
