import React from 'react';
import './footer.css'; 
import { FaInstagram,FaFacebook, FaWhatsapp, FaLinkedin } from 'react-icons/fa'; 




function Footer() {
  return (
    <footer className="footer">
      <div className="footerContent">
        <div className="contentBox">
          <div className='logo'>
            <div>
              <img src="/Images/Logo.png" alt="" width="170px" height="170px" />
            </div>
          </div>
          <div className='social-links1'>

            <a href="https://www.instagram.com/equiverge_technologies_?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D" target="_blank" rel="noopener noreferrer"><FaInstagram className='social-icons2' /></a>

            <a href="https://www.facebook.com/people/Equiverge-Technologies/61550080665812/" target="_blank" rel="noopener noreferrer"><FaFacebook  className='social-icons2' /></a>

            <a href="https://api.whatsapp.com/send?phone=%2B918712921597&data_filter_required=ARBN3JJ-Vozen0jnTk13qfhOMpp4KSVOnLRPaAnYRmdXfLmuzRiNjP5xi3VeK-oLJmF4pNFtbzL1iCjTKuj5wZejLIDp-0MhOhYgPUVSsLDA9vXucQN5zsC_1A56NUoEZ594hWz62m1McRgeGVelMp3DSA&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR060OmQpYzlWY4php3Zwp9lU_u4uo1Q2A1CesywI1Y3fRbDMF739jd3c4Y" target="_blank" rel="noopener noreferrer"><FaWhatsapp className='social-icons2' /></a>

            <a href="https://www.linkedin.com/in/equiverge-technologies-941b8729b" target="_blank" rel="noopener noreferrer"> <FaLinkedin className='social-icons2' /></a>  
       
          </div>
        </div>
        <div className="contentBox">
          <h3>Qucik  Links</h3>
          <ul className="social">
            <li><a href="/">--- HOME</a></li>
            <li><a href="/course">--- COURSES</a></li>
            <li><a href="/about">--- ABOUT</a></li>
            <li><a href="/blog">--- BLOG</a></li>
            <li><a href="/contact">--- CONTACT</a></li>
          </ul>
        </div>
        <div className="contentBox">
          <h3>About Us</h3>
          <ul className="social">
          <li><a href="/design-creative">Design & Creative</a></li>
        <li><a href="/communication-skills">Communication Skills</a></li>
        <li><a href="/real-time-works">Real-Time Works</a></li>
        <li><a href="/programming">Programming</a></li>
        <li><a href="/architecture">Architecture</a></li>
        <li><a href="/architecture">Hire From US</a></li>
        <li><a href="/architecture">Careers</a></li>
      </ul>
        </div>
        <div className="contentBox">
          <h3>Follow Us</h3>
          <ul className="social">
            <li>
              <a href="https://www.instagram.com/equiverge_technologies_?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D" target="_blank" rel="noopener noreferrer" >
                Instagram
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/people/Equiverge-Technologies/61550080665812/" target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
            </li>
            <li>
              <a href="https://api.whatsapp.com/send?phone=%2B918712921597&data_filter_required=ARBN3JJ-Vozen0jnTk13qfhOMpp4KSVOnLRPaAnYRmdXfLmuzRiNjP5xi3VeK-oLJmF4pNFtbzL1iCjTKuj5wZejLIDp-0MhOhYgPUVSsLDA9vXucQN5zsC_1A56NUoEZ594hWz62m1McRgeGVelMp3DSA&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR060OmQpYzlWY4php3Zwp9lU_u4uo1Q2A1CesywI1Y3fRbDMF739jd3c4Y" target="_blank" rel="noopener noreferrer" >
                WhatsApp
              </a>
            </li>
            <li>
              <a href="https://profile.indeed.com/?hl=en_IN&co=IN&from=gnav-homepage&_ga=2.149815600.78025459.1700546123-1887691946.1700131664" target="_blank" rel="noopener noreferrer">
                Indeed
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="copyrights">
        <hr />
        <p> © Copyright CoreSide Technologies @ 2023 All Rights Reserved ..</p>
      </div>
    </footer>
  );
}

export default Footer;
