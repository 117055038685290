import React from 'react';
import Slider from 'react-slick';
import './AboutSlider.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const images = [
  {
    path: '/Images/About/slide-1.jpg',
    alt: 'Software Training 1',
  },
  {
    path: '/Images/About/slide-2.jpg',
    alt: 'Software Training 2',
  },
  {
    path: '/Images/About/slide-3.png',
    alt: 'Software Training 3',
  },
];

const SoftwareTrainingSlider = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className='bg-img'>
      <div className='training'>
        <Slider className='training-slider' {...sliderSettings}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image.path} alt={image.alt} style={{ width: '600px' }} />
            </div>
          ))}
        </Slider>
        <div className='training-container'>
          <h2>Software Training Excellence</h2>
          <h3>Importance of Software Training</h3>
          <p>
            Software training is crucial in today’s technology-driven world. It equips individuals
            with the necessary skills to effectively use software tools, enhancing productivity and
            efficiency in various fields. Understanding software applications is essential for both
            personal and professional growth.
          </p>
          {/* <h3>Key Training Practices</h3>
          <p>
            Effective software training involves a combination of theoretical knowledge and practical
            experience. Key practices include hands-on workshops, interactive tutorials, and
            real-world projects. Regular assessments, expert guidance, and up-to-date course content
            are essential components of a comprehensive training program.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default SoftwareTrainingSlider;
