import React from 'react';
import './Vision.css';

const Vision = () => {
  return (
    <main className="main-container">
      <h1 className="main-title">Your personalized learning journey</h1>
      <p className="main-subtitle">Smarty gives you the platform and knowledge you need to succeed. One tool could change everything.</p>

      <article className="article-container">
        <div className="steps-container">
          <div className="step">
            <p className="number">1<span className="dot"></span></p>
            <h2 className="section-title">Personality Development</h2>
          <p className="section-description">Personality development is a lifelong process. Our courses cover everything from boosting your confidence to developing effective communication skills. Unlock your potential and achieve personal and professional growth.</p>
         </div>
          <div className="step">
            <p className="number">2<span className="dot"></span></p>
            <h2 className="section-title">Best Courses</h2>
          <p className="section-description">Explore our curated list of the best courses in various fields such as technology, business, arts, and sciences. Whether you're looking to advance your career or learn something new, we have the right course for you.</p>
        </div>
          <div className="step">
            <p className="number">3<span className="dot"></span></p>
            <h2 className="section-title">Aptitude Training</h2>
          <p className="section-description">Sharpen your problem-solving skills with our aptitude training. We offer a variety of practice tests and exercises in logical reasoning, numerical ability, and verbal ability to help you excel in competitive exams and job assessments.</p>
        </div>
          <div className="step">
            <p className="number">4<span className="dot"></span></p>
            <h2 className="step-title">Create a success story</h2>
            <p className="step-description">With access to online learning resources anyone can transform their lives in meaningful ways.</p>
          </div>
        </div>
        <img
          src="/Images/About/About_2.avif"
          alt="Learning"
          className="main-image"
        />
      </article>
    </main>
  );
}

export default Vision;
