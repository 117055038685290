import React from 'react';
import './InternShip.css'; // Assuming your CSS file exists

const Internship = () => {
  // Replace with your institute's internship program details
  return (
    <div className="background-container">
      <div className="image-containerbg">
        {/* Left side content (image) */}
        <img src="/Images/Home/intern-1.jpg" alt="Background" />
      </div>

      <div className="content-containerbg">
        {/* Right side content */}
        <h1>Real Time Works</h1>
        <p>Gain valuable real-world experience through Kaizen. Apply your skills
          and knowledge to real-world projects under the guidance of experienced professionals.
          Develop practical skills and build your resume in a supportive learning environment.</p>
        <button className="learn-more">
          Learn More
        </button>
      </div>
    </div>
  );
};

export default Internship;
