// ShowcaseComponent.jsx

import React from 'react';
import '../Blog/BlogSec.css';
const AboutBody = () => {
  return (
    <section className="showcase">
      <img
        src="https://media.istockphoto.com/id/1290864946/photo/e-learning-education-concept-learning-online.jpg?s=612x612&w=0&k=20&c=y1fQ-3wbsvdDaMn-cuHPibcgozOxKQS99mIgz6DFcVA="
        alt="bg-img"
      />
      <div className="overlay">
        <h2>HOME | <span>About</span></h2>
      </div>
    </section>
  );
};

export default AboutBody
