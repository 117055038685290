import React from 'react';
import Header from './Navbar'
import Home from './MainSection';
import Goals from './Content';
import Testimonals from './Testimonial';
import SimpleSlider from './Clients';
import Footer from './Footer';
import Stats from '../About/About';
import Internship from './InternShip';
import HomeSlider from './HomeSlider';

function MainHome() {
  return (
    <div className="App">
    
      <Home />
      
     
     <Stats />

    
      <Goals/>
     <Internship/>
      <Testimonals />
      <SimpleSlider/>
     
    </div>
  );
}

export default MainHome;
