import React from 'react';
import './Courses.css';

const courses = [
  {
    title: 'Dot_Net',
    description: 'Learn the fundamentals of HTML including tags, attributes, and semantic markup.',
    imageUrl: '/Images/Team/dot_net.png',
    altText: 'HTML Course Image',
    formUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSdVNfUev2XBo0lOsK768x128SNohVOntjVi9wsqutBTm4g9Aw/viewform',
  },
  {
    title: 'Java',
    description: 'Master CSS styling techniques such as selectors, box model, flexbox, and grid.',
    imageUrl: '/Images/Course/java.jpg',
    altText: 'CSS Course Image',
    formUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSdVNfUev2XBo0lOsK768x128SNohVOntjVi9wsqutBTm4g9Aw/viewform',
  },
  {
    title: 'Python',
    description: 'Explore the core concepts of JavaScript including variables, functions, and loops.',
    imageUrl: '/Images/Course/python.png',
    altText: 'JavaScript Course Image',
    formUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSdVNfUev2XBo0lOsK768x128SNohVOntjVi9wsqutBTm4g9Aw/viewform',
  },
  {
    title: 'AWS',
    description: 'Build dynamic web applications with React.js, including state management and component-based architecture.',
    imageUrl: '/Images/Course/aws.webp',
    altText: 'React Course Image',
    formUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSdVNfUev2XBo0lOsK768x128SNohVOntjVi9wsqutBTm4g9Aw/viewform',
  },
  {
    title: 'Data Science',
    description: 'Discover the power of Next.js for server-side rendering, routing, and API integration.',
    imageUrl: '/Images/Course/data-science.webp',
    altText: 'Next.js Course Image',
    formUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSdVNfUev2XBo0lOsK768x128SNohVOntjVi9wsqutBTm4g9Aw/viewform',
  },
  {
    title: 'Data Analyst',
    description: 'Learn TypeScript to enhance the development of scalable and maintainable web applications.',
    imageUrl: '/Images/Course/DataAnalyst.jpg',
    altText: 'TypeScript Course Image',
    formUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSdVNfUev2XBo0lOsK768x128SNohVOntjVi9wsqutBTm4g9Aw/viewform',
  },
];

const CourseWebsite = () => {
  return (
    <div>
      <div className="container-course">
        <h2 className="container-heading">Featured Courses</h2>
        <div className="course-list">
          {courses.map((course, index) => (
            <a href={course.formUrl} className="course" key={index}>
              <div>
                <img src={course.imageUrl} alt={course.altText} />
                <h2>{course.title}</h2>
                <p>{course.description}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseWebsite;