import React, { useState, useEffect } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Testimonial.css';

const Testimonials = () => {
  const testimonials = [
    {
      text: "The experience at CoreSide Institute was transformative! The instructors were incredibly knowledgeable and created an encouraging learning environment. Highly recommend for anyone looking to advance their skills!",
      author: "Alice",
      role: "Software Engineer",
    },
    {
      text: "CoreSide Institute exceeded my expectations. The courses were well-structured, and the instructors were very supportive. A fantastic place to learn and grow!",
      author: "Bob",
      role: "Project Manager",
    },
    {
      text: "Learning at CoreSide Institute was a game-changer. The practical approach and expert guidance made the learning process seamless and effective. Absolutely satisfied!",
      author: "Charlie",
      role: "Quality Analyst",
    },
    {
      text: "Enrolling at CoreSide Institute was the best decision I made for my career. The hands-on training and experienced instructors provided a solid foundation. Highly recommended!",
      author: "Dave",
      role: "Full Stack Developer",
    },
  ];

  const [sliderSettings, setSliderSettings] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSliderSettings({
          ...sliderSettings,
          slidesToShow: 1,
          slidesToScroll: 1,
        });
      } else {
        setSliderSettings({
          ...sliderSettings,
          slidesToShow: 2,
          slidesToScroll: 2,
        });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sliderSettings]);

  return (
    <div className="testimonial-slider mb-5">
      <div className='testimonialSlider'>
        <h6>Reviews</h6>
        <h2 className="mt-3 content-title">Check what our students say about us</h2>
      </div>
      <div className='containerTest'>
        <Slider {...sliderSettings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-item">
              <p className="testimonial-text">{testimonial.text}</p>
              <div className="testimonial-author">
                <h5 className="mb-0 text-capitalize" style={{ fontWeight: '600', fontSize: '1.5rem' }}>{testimonial.author}</h5>
                <p className='role-text'>{testimonial.role}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
