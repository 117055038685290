import React, { useState } from 'react';
import './Home.css';

const EmailForm = () => {
  const [email, setEmail] = useState('');

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    console.log('Email submitted:', email);
    // Add your form submission logic here
  };

  return (
    <form className="form-1" onSubmit={(e) => e.preventDefault()}>
      <input
        type="email"
        className="form__field"
        placeholder="Your E-Mail Address"
        value={email}
        onChange={handleInputChange}
      />
      <button
        type="button"
       
        onClick={handleSubmit}
      >
        Send
      </button>
    </form>
  );
};

function Home() {
  return (
    <div className="container-1">
      <div className="content">
        <h1>Career Campus</h1>
        <p>Continuous Improvement and Encourages people to make incremental changes to their habits, routines, and thought patterns.</p>
        <EmailForm />
      </div>
      <div className="image">
        <img src="/Images/Kaizen-Process.webp" alt="Right Side" />
      </div>
    </div>
  );
}

export default Home;
