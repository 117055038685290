import React from 'react';
import './Team.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

const TeamSection = () => {
  const teamMembers = [
    {
      name: 'Vin Diesel',
      position: 'Senior App Developer',
      image: '/Images/Team/team_1.jpg',
      facebook: '#',
      instagram: '#',
      twitter: '#',
    },
    {
      name: 'David Corner',
      position: 'Front End Developer',
      image: '/Images/Team/team-2.jpg',
      facebook: '#',
      instagram: '#',
      twitter: '#',
    },
    {
      name: 'Tom Cruise',
      position: 'Full Stack Developer',
      image: '/Images/Team/team-3.jpg',
      facebook: '#',
      instagram: '#',
      twitter: '#',
    },
    {
        name: 'Vin Diesel',
        position: 'Senior App Developer',
        image: '/Images/Team/team-4.jpg',
        facebook: '#',
        instagram: '#',
        twitter: '#',
      },

         {
      name: 'Vin Diesel',
      position: 'Senior App Developer',
      image: '/Images/Team/team-5.webp',
      facebook: '#',
      instagram: '#',
      twitter: '#',
    },
  ];

  return (
    <div className="main-team">
         <div className="team-intro">
        <h2>Meet Our Team</h2>
        <p>We are a group of passionate developers committed to delivering high-quality software solutions.</p>
      </div>

      <div className='main'>
      {teamMembers.map((member, index) => (
        <div key={index} className="profile-card">
          <div className="img">
            <img src={member.image} alt={member.name} />
          </div>
          <div className="caption">
            <h3>{member.name}</h3>
            <p>{member.position}</p>
            <div className="social-links">
              <a href={member.facebook}><FontAwesomeIcon icon={faFacebook} /></a>
              <a href={member.instagram}><FontAwesomeIcon icon={faInstagram} /></a>
              <a href={member.twitter}><FontAwesomeIcon icon={faTwitter} /></a>
            </div>
          </div>
        </div>
        
      ))}
      </div>
    </div>
  );
}

export default TeamSection;
