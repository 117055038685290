import React from 'react';
import './Blog.css'; // Assuming this is where you have your styles

const Blog = () => {
   

   

    // Array of blog posts with images and unique content
    const blogPosts = [
        {
            image: "https://5.imimg.com/data5/SELLER/Default/2023/5/309687862/GT/RQ/YF/8675179/online-mock-test-software-500x500.jpg",
            date: "15 JAN",
            title: "Mastering Online Mock Tests",
            description: "Learn how to effectively prepare for and excel in online mock tests. Get tips on time management and question-solving strategies...",
            content: `
                <p><strong>Introduction:</strong><br/> Online mock tests are crucial for students preparing for various exams. They help in time management and provide a real-exam experience.</p>
                <p><strong>Key Points:</strong></p>
                <ul>
                    <li><strong>Time Management:</strong> Online mock tests help you manage your time effectively.</li>
                    <li><strong>Real Exam Experience:</strong> These tests simulate the actual exam environment.</li>
                    <li><strong>Identify Weaknesses:</strong> Regular practice helps identify weak areas.</li>
                </ul>
                <p><strong>Tips:</strong></p>
                <ul>
                    <li>Take tests regularly.</li>
                    <li>Analyze your performance after each test.</li>
                    <li>Focus on your weak areas.</li>
                </ul>
            `
        },
        {
            image: "https://cache.careers360.mobi/media/presets/700X350/article_images/2018/07/10/how-to-prepare-for-NEET-using-mock-tests.webp",
            date: "20 FEB",
            title: "Mock Interview",
            description: "Discover the best strategies for using mock tests to prepare for NEET. Understand the importance of practice and review...",
            content: `
                <p><strong>Introduction:</strong><br/> Preparing for NEET with mock tests is essential. They help in understanding the exam pattern and managing time efficiently.</p>
                <p><strong>Key Strategies:</strong></p>
                <ul>
                    <li><strong>Consistent Practice:</strong> Regular mock tests improve speed and accuracy.</li>
                    <li><strong>Time Management:</strong> Allocate time wisely for each section.</li>
                    <li><strong>Review:</strong> Analyze your performance to identify weak areas.</li>
                </ul>
                <p><strong>Tips:</strong></p>
                <ul>
                    <li>Start with easier sections to build confidence.</li>
                    <li>Review mistakes and avoid repeating them.</li>
                    <li>Join a study group for discussions and doubt clearance.</li>
                </ul>
            `
        },
        {
            image: "https://www.flexiquiz.com/Uploads/Guides/3138f118-b599-4b63-89f2-f27560200bd7_1764fe23-322f-4a0d-987f-bbb67281ff9e",
            date: "10 MAR",
            title: "Effective Use of Coding Challenges",
            description: "Explore how online Coding Challenges can enhance your learning process. Learn to create, take, and analyze quizzes for maximum benefit...",
            content: `
                <p><strong>Introduction:</strong><br/> Coding challenges are an excellent way to improve programming skills and prepare for coding interviews.</p>
                <p><strong>Key Points:</strong></p>
                <ul>
                    <li><strong>Practice Regularly:</strong> Consistent practice helps in understanding various coding problems.</li>
                    <li><strong>Learn from Mistakes:</strong> Analyze and learn from errors to avoid repeating them.</li>
                    <li><strong>Improve Problem-Solving Skills:</strong> Challenges enhance logical and analytical thinking.</li>
                </ul>
                <p><strong>Tips:</strong></p>
                <ul>
                    <li>Start with easy problems and gradually move to harder ones.</li>
                    <li>Participate in online coding competitions.</li>
                    <li>Join coding communities for support and guidance.</li>
                </ul>
            `
        },
        {
            image: "https://blogassets.leverageedu.com/blog/wp-content/uploads/2020/06/09204138/Online-Test.jpg",
            date: "05 APR",
            title: "Boosting Performance in Online Tests",
            description: "Tips and tricks to boost your performance in online tests. From technical setup to mental preparation, get all the insights...",
            content: `
                <p><strong>Introduction:</strong><br/> Boosting performance in online tests requires a combination of technical readiness and mental preparation.</p>
                <p><strong>Key Points:</strong></p>
                <ul>
                    <li><strong>Technical Setup:</strong> Ensure a stable internet connection and a quiet environment.</li>
                    <li><strong>Practice:</strong> Regular mock tests to familiarize yourself with the format.</li>
                    <li><strong>Mental Preparation:</strong> Stay calm and focused during the test.</li>
                </ul>
                <p><strong>Tips:</strong></p>
                <ul>
                    <li>Set a timer while taking practice tests to improve your time management skills.</li>
                    <li>Review the solutions and explanations provided with the test papers.</li>
                    <li>Join study groups or forums to discuss difficult questions and concepts.</li>
                </ul>
            `
        },
        {
            image: "https://static.toiimg.com/thumb/msid-70306649,width-1200,height-900,resizemode-4/.jpg",
            date: "25 JUN",
            title: "Maximizing Benefits of Mock Tests",
            description: "Mock tests are a powerful tool for exam preparation. They help you familiarize yourself with the exam format, manage your time, and identify areas for improvement...",
            content: `
                <p><strong>Introduction:</strong><br/> Mock tests are a powerful tool for exam preparation. They help you familiarize yourself with the exam format, manage your time, and identify areas for improvement.</p>
                <p><strong>Key Points:</strong></p>
                <ul>
                    <li><strong>Understanding the Exam Format:</strong> Take mock tests that replicate the actual exam format, including question types, difficulty levels, and time constraints.</li>
                    <li><strong>Identifying Weaknesses:</strong> Use the results of mock tests to pinpoint areas where you need to focus your study efforts.</li>
                    <li><strong>Improving Time Management:</strong> Practice pacing yourself to ensure you can complete the exam within the allotted time.</li>
                    <li><strong>Building Confidence:</strong> Regularly taking mock tests can help reduce anxiety and build confidence for the actual exam.</li>
                </ul>
                <p><strong>Tips:</strong></p>
                <ul>
                    <li>Review your answers thoroughly to understand your mistakes.</li>
                    <li>Simulate exam conditions to build test-taking stamina.</li>
                    <li>Mix up your study materials to get a well-rounded understanding of the topics.</li>
                </ul>
            `
        },
        {
            image: "https://c.ndtvimg.com/2020-07/31lv2gjo_laptops_625x300_22_July_20.jpg?im=FeatureCrop,algorithm=dnn,width=545,height=307",
            date: "30 JUL",
            title: "Cracking Coding Challenge",
            description: "The role of technology in education, specifically focusing on online tests. How they are changing the landscape of learning...",
            content: `
                <p><strong>Introduction:</strong><br/> Technology has revolutionized education, and online tests are a significant part of this transformation. They offer flexibility, accessibility, and a wide range of assessment tools.</p>
                <p><strong>Key Points:</strong></p>
                <ul>
                    <li><strong>Accessibility:</strong> Online tests can be accessed from anywhere, making education more inclusive.</li>
                    <li><strong>Flexibility:</strong> They allow for a variety of question types and adaptive testing methods that can cater to different learning styles and needs.</li>
                    <li><strong>Immediate Feedback:</strong> Online tests can provide instant results and feedback, helping learners to quickly understand their progress and areas for improvement.</li>
                    <li><strong>Data Analytics:</strong> Advanced analytics can help educators track student performance and adjust teaching strategies accordingly.</li>
                </ul>
                <p><strong>Tips:</strong></p>
                <ul>
                    <li>Embrace technology and familiarize yourself with different online testing platforms.</li>
                    <li>Take advantage of the flexibility to customize your learning experience.</li>
                    <li>Use the feedback from online tests to guide your study efforts and improve continuously.</li>
                </ul>
            `
        },
        {
            image: "https://onlinetestpaper.com/wp-content/uploads/2021/01/Online-Test-Paper-back.png",
            date: "15 MAY",
            title: "Comprehensive Guide to Online Test Papers",
            description: "A comprehensive guide to understanding and utilizing online test papers. Learn how to choose the right tests and make the most of them...",
            content: `
                <p><strong>Introduction:</strong><br/> Online test papers are a valuable resource for students preparing for exams. They help in understanding the exam pattern, practicing time management, and improving overall performance.</p>
                <p><strong>Key Points:</strong></p>
                <ul>
                    <li><strong>Understanding the Exam Pattern:</strong> Online test papers provide a clear idea of the question types and difficulty level of the exam.</li>
                    <li><strong>Time Management:</strong> Practicing with online test papers helps students manage their time effectively during the actual exam.</li>
                    <li><strong>Self-Assessment:</strong> Regular practice with these papers enables students to assess their strengths and weaknesses.</li>
                    <li><strong>Building Confidence:</strong> Frequent practice with online test papers helps in building confidence and reducing exam anxiety.</li>
                </ul>
                <p><strong>Tips:</strong></p>
                <ul>
                    <li>Choose online test papers that closely match the syllabus and pattern of your actual exam.</li>
                    <li>Analyze your performance after each test to identify areas for improvement.</li>
                    <li>Practice regularly to improve your speed and accuracy.</li>
                    <li>Use online resources and discussion forums to clarify doubts and learn new strategies.</li>
                </ul>
            `
        },
       
    ];

    const recentPostImages = [
        "https://www.shutterstock.com/image-photo/group-young-people-having-discussion-260nw-1168363339.jpg",
        "https://www.simplilearn.com/ice9/free_resources_article_thumb/Top_8_Group_Discussion_Tips.jpg",
        "https://neurosensum.com/wp-content/uploads/2020/05/online-group-discussion1.jpg"
    ];

    const bookImages = [
        "https://pentagonspace.in/assets_batch/python%20overview.png",
       
    ];

    return (
        <>
            {/* BLOG SECTION */}
            <div className="blog_container">
                <div className="blog_content">
                    <div className="left_content">
                        {/* BLOG CARDS */}
                        {blogPosts.map((post, index) => (
                            <div key={index} className="blog_card">
                                <a href="article.html" className="figure">
                                    <img src={post.image} alt="" loading="lazy" />
                                    <span className="tag">{post.date}</span>
                                </a>
                                <section>
                                    <a href="article.html" className="title">{post.title}</a>
                                    <p>{post.description}</p>
                                </section>

                                
                            </div>
                        ))}
                    </div>
                    <div className="columns comments">
                        <span className="title"> Recent Comments <button type="button" title="Explore More" className="btn-link"><i className="fa fa-share"></i></button></span>
                        <section>
                        <div className="marquee2">
          <p>"The mock interview session was incredibly helpful in boosting my confidence!"</p>
          <p>"I loved the tips on personality development, they are very practical and easy to follow."</p>
          <p>"The online coding tests helped me prepare for my job interviews more effectively."</p>
        </div>
                        </section>
                    </div>
                    <button className="learn-more">Load more</button>
                </div>

                <div className="blog_content right_content">
                    {/* SEARCH COLUMN */}
                    <div className="columns search_column">
                        <section className="search">
                            <form>
                                <fieldset>
                                    <input type="text" name="search" placeholder="Search..." maxLength="100" required />
                                </fieldset>
                                <fieldset>
                                    <button type="submit" className="btn1">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </fieldset>
                            </form>
                        </section>
                    </div>

                    {/* BOOKS COLUMN */}
                    <div className="columns books">
                        <span className="title">Recommended Books <button type="button" title="Explore More" className="btn-link"><i className="fa fa-share"></i></button></span>
                        
                        <section>
                            <div className="cards">
                                {bookImages.map((image, index) => (
                                    <div key={index} className={`card_part card_part-${index + 1}`} style={{ backgroundImage: `url(${image})` }}></div>
                                ))}
                            </div>

                            
                        </section>
                    </div>

                    {/* CATEGORIES COLUMN */}
                    <div className="columns categories">
                        <span className="title">Categories</span>
                        <section>
                            {["Mock Interviews", "Online Tests", "Coding Challenges", "Personality Development", "Aptitude & Reasoning", "Career Guidance", "Success Stories"].map((category, index) => (
                                <button key={index} type="button" className="btn-link">{category}</button>
                            ))}
                        </section>
                    </div>

                    {/* POSTS COLUMN */}
                    <div className="columns posts">
                        <span className="title">Recent Posts <button type="button" title="Explore More" className="btn-link"><i className="fa fa-share"></i></button></span>
                        <section>
                            {recentPostImages.map((image, index) => (
                                <div key={index} className="recent-post">
                                    <img src={image} alt="" loading="lazy" />
                                    <p>Top Strategies for Acing Online Coding Tests</p>
                                </div>
                            ))}
                        </section>
                    </div>

                    {/* COMMENTS COLUMN */}
                   

                    {/* SOCIAL MEDIA ICONS */}
                   
                </div>
            </div>
        </>
    );
};

export default Blog;
